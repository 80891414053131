<template>
  <div v-loading="loading">
    <p>{{ displayData.content }}</p>
    <el-progress :text-inside="true" :stroke-width="20" :percentage="displayData.percentage" :color="colorList" />

    <WarningDialog
      v-if="modal.warning"
      hideCancel
      :content="displayText.modal.limitWarning.content"
      @close="modal.warning = false"
      @confirm="modal.warning = false"
    >
      <template slot="body">
        <div style="padding-top: 16px">
          <a class="text-primary-100 underline" :href="bobLineAt" target="_blank" rel="noopener noreferrer">
            {{ displayText.modal.btn.contact }}
          </a>
        </div>
      </template>
    </WarningDialog>
  </div>
</template>

<script>
import { GetMembersCount } from '@/api/member'
import store from '@/store'
import { useShop } from '@/use/shop'
import { apiFormatAdaptor } from '@/utils/api'
import { get, isNull } from 'lodash'
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { bobLineAt } from '@/config/line'

export default defineComponent({
  name: 'MemberLimitProgressBar',
  components: {
    WarningDialog,
  },
  setup (props) {
    const loading = ref(false)
    const { shopId } = useShop()
    const modal = reactive({
      warning: false,
    })
    const totalMemberCount = ref(null)
    const colorList = [
      { color: 'var(--action)', percentage: 50 },
      { color: 'var(--warning)', percentage: 100 },
      { color: 'var(--danger)', percentage: 100 },
    ]
    const memberRegisterLimit = computed(() => {
      return get(store.state, 'user.planLimit.memberLimit') || null
    })

    const checkLimit = () => {
      if (isNull(memberRegisterLimit.value) || memberRegisterLimit.value === 0) return
      if (isNull(totalMemberCount.value)) return
      if (totalMemberCount.value >= memberRegisterLimit.value) {
        modal.warning = true
      }
    }

    const refresh = async () => {
      loading.value = true
      const [res, err] = await apiFormatAdaptor(GetMembersCount)({
        shopId: shopId.value,
      })
      loading.value = false

      if (err) {
        window.$message.error(err)
        return
      }

      totalMemberCount.value = res || 0
      checkLimit()
    }

    const displayData = computed(() => {
      const data = {
        percentage: 0,
        content: '目前會員註冊總數：讀取中 / 合約方案會員註冊數上限：讀取中',
      }

      if (!loading.value) {
        data.content = `目前會員註冊總數：${totalMemberCount.value} / 合約方案會員註冊數上限：${memberRegisterLimit.value}`
        data.percentage = Math.floor((totalMemberCount.value / memberRegisterLimit.value) * 100)
        if (isNull(memberRegisterLimit.value) || memberRegisterLimit.value === 0) data.content = '發生錯誤'
        if (isNull(totalMemberCount.value)) data.content = '發生錯誤'
      }

      return data
    })

    const displayText = computed(() => {
      const text = {
        modal: {
          limitWarning: {
            content: '您的分店會員註冊數額度已達上限，系統已暫停該分店的會員註冊與新增會員功能。請聯繫客服人員或所屬業務提升您的會員註冊數額度。',
          },
          btn: {
            contact: '點我前往聯繫客服小幫手',
          },
        },
      }
      return text
    })

    const reachedLimit = computed(() => {
      if (isNull(memberRegisterLimit.value) || memberRegisterLimit.value === 0) return false
      return totalMemberCount.value >= memberRegisterLimit.value
    })

    onBeforeMount(async () => {
      await refresh()
    })

    return {
      loading,
      displayData,
      displayText,
      colorList,
      modal,
      totalMemberCount,
      memberRegisterLimit,
      reachedLimit,
      bobLineAt,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
