export const reservationListFilterOptions = [
  { label: '電話號碼', value: 'phone' },
  { label: '收件地點', value: 'userAddress' },
  { label: '訂單類型', value: 'orderType' },
  { label: '預約日期', value: 'date' },
  { label: '預約時間', value: 'time' },
  { label: '服務項目', value: 'service' },
  { label: '服務人員', value: 'serviceUnit' },
  { label: '服務設備', value: 'resourceItem' },
  { label: '預約來源', value: 'origin' },
  { label: '訂單總額', value: 'orderPrice' },
  { label: '應付訂金', value: 'depositPrice' },
  { label: '付款方式', value: 'paymentType' },
  { label: '建立時間', value: 'createdAt' },
  { label: '報到時間', value: 'checkInTime' },
  { label: '報到門市', value: 'checkInBranch' },
  { label: '結帳付款狀態', value: 'checkoutPaymentStatus' },
  { label: '合併訂單狀態', value: 'batchOrderStatus' },
  { label: '實付金額', value: 'checkoutTotalPrice' },
  { label: '結帳付款方式', value: 'checkoutPaymentType' },
  { label: '付款狀態', value: 'depositPaymentStatus' },
]

export const memberReservationListFilterOptions = [
  { label: '訂單類型', value: 'orderType' },
  { label: '收件地點', value: 'userAddress' },
  { label: '預約日期', value: 'date' },
  // { label: '預約時間', value: 'time' },
  { label: '服務項目', value: 'service' },
  { label: '服務人員', value: 'serviceUnit' },
  { label: '預約來源', value: 'origin' },
  { label: '訂單狀態', value: 'orderStatus' },
  { label: '訂單總額', value: 'orderPrice' },
  { label: '應付訂金', value: 'depositPrice' },
  { label: '付款方式', value: 'paymentType' },
  { label: '結帳付款狀態', value: 'checkoutPaymentStatus' },
  { label: '實付金額', value: 'checkoutTotalPrice' },
  { label: '結帳付款方式', value: 'checkoutPaymentType' },
  { label: '付款狀態', value: 'depositPaymentStatus' },
  { label: '付款狀態', value: 'paymentStatus' },
  { label: '合併訂單狀態', value: 'batchOrderStatus' },
  // { label: '結帳付款狀態', value: 'checkoutPaymentStatus' },
  // { label: '付款狀態', value: 'depositPaymentStatus' },
]

export const memberManageFilterOptions = [
  { label: '電話號碼', value: 'phone' },
  { label: '生日', value: 'birthday' },
  { label: '等級', value: 'level' },
  { label: '標籤', value: 'tag' },
  { label: '聯絡地址', value: 'address' },
  { label: '登入方式', value: 'loginType' },
  { label: '黑名單', value: 'blackList' },
]
